import React, { Component } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import cn from 'classnames';
import Lightbox from 'react-image-lightbox';
import Checkbox from '@material-ui/core/Checkbox';
import NavBarLoggedIn from 'components/NavBarLoggedIn';
import Loading from 'components/Loading';
import Footer from 'components/Footer';
import { loadAccount } from 'actions/accountActions';
import { loadFinancialPriorities, loadTask, toggleTask, loadTaskFromLink } from 'actions/dataActions';
import AddIcon from 'images/icons/add.svg';
import RemoveIcon from 'images/icons/remove.svg';
import './Task.scss';
import './BlogContent.scss';
import 'react-image-lightbox/style.css';

const mapStateToProps = state => ({
    account: state.account,
    financialPriorities: state.data.financialPriorities,
    task: state.data.task,
});

const mapDispatchToProps = dispatch => ({
    loadAccount: () => {
        dispatch(loadAccount());
    },
    loadFinancialPriorities: (user) => {
        dispatch(loadFinancialPriorities(user));
    },
    loadTask: (user, taskId) => {
        dispatch(loadTask(user, taskId));
    },
    toggleTask: (task, user) => {
        dispatch(toggleTask(task, user));
    },
    loadTaskFromLink: (url, user) => {
        dispatch(loadTaskFromLink(url, user));
    },
});

class Task extends Component {
    constructor(props) {
        super(props);

        this.editNotesTimeout = null;
        this.taskId = window.location.search.split('=')[1];
        this.contentRef = React.createRef();

        this.state = {
            loaded: false,
            dataLoaded: false,
            task: null,
            notesVisible: false,
            lightboxOpen: false,
            lightboxImage: null,
            mobile: window.innerWidth < 1440,
        };

        window.addEventListener('resize', this.handleResize);
    }

    componentDidMount() {
        this.props.loadAccount();
    }

    componentDidUpdate() {
        if (this.contentRef.current) {
            this.contentRef.current.addEventListener('click', this.clickEventListener);
        }

        window.addEventListener('scroll', this.scrollEventListener);
    }

    componentWillUnmount() {
        if (this.contentRef.current) {
            this.contentRef.current.removeEventListener('click', this.clickEventListener);
        }

        window.removeEventListener('scroll', this.scrollEventListener);
    }

    UNSAFE_componentWillReceiveProps (nextProps) {
        let state = {};

        let account = nextProps.account;
        if ((!account.loaded && account.error.error) || (account.loaded && account.user)) {
            state.loaded = true;

            if (!nextProps.financialPriorities && !nextProps.task) {
                this.props.loadFinancialPriorities(account.user);
            }
        }

        if (nextProps.task) {
            state.dataLoaded = true;
            state.task = nextProps.task;
            state.task.id = this.taskId;
            state.task.notes = '';
        } else {
            if (account.loaded && account.user && !nextProps.success && !nextProps.error && !nextProps.financialPriorities) {
                this.props.loadTask(account.user, this.taskId);
            }
        }

        this.setState(state);
    }

    handleResize = () => {
        this.setState({ mobile: window.innerWidth < 1440 });
    }

    clickEventListener = (e) => {
        if (e.target.localName === 'a') {
            // Skip all this for anchor links //
            if (e.target.attributes.href.nodeValue === e.target.hash) {
                setTimeout(() => {
                    window.scrollTo(0, window.scrollY - 205);
                }, 10);
                return;
            }

            e.preventDefault();

            const url = e.target.href;
            const apURL = 'https://www.moneyswell.com/action-plan/';

            if (url.startsWith(apURL)) {
                this.props.loadTaskFromLink(url, this.props.account.user);
            } else {
                window.open(url, '_blank');
            }
        } else if (e.target.localName === 'img' && e.target.parentElement.localName === 'a') {
            e.preventDefault();
            this.setState({
                lightboxOpen: true,
                lightboxImage: e.target.parentElement.href,
            });
        }
    }

    closeLightbox = () => {
        this.setState({
            lightboxOpen: false,
            lightboxImage: null,
        });
    }

    doMainAction = (e) => {
        e.stopPropagation();

        let task = this.state.task;

        if (task.visible) {
            task.status = (task.status) ? 0 : 1;
        } else {
            task.visible = (task.visible) ? false : true;
        }

        this.props.toggleTask(task, this.props.account.user);

        this.setState({ task: task });
    }

    removeTask = () => {
        let task = this.state.task;
        task.visible = false;
        this.props.toggleTask(task, this.props.account.user);
        this.setState({ task: task });
    }

    saveNotes = (e) => {
        let task = this.state.task;
        task.notes = e.target.value;

        if (this.editNotesTimeout) {
            clearTimeout(this.editNotesTimeout);
        }

        this.editNotesTimeout = setTimeout(() => {
            this.props.toggleTask(task, this.props.account.user);
        }, 750);

        this.setState({ task: task });
    }

    toggleNotes = (e) => {
        e.preventDefault();
        this.setState({ notesVisible: !this.state.notesVisible });
    }

    goBack = (e) => {
        e.preventDefault();
        window.location.href = `/plan?task=${this.state.task.id}`
    }

    processContent = (content) => {
        const regexp = /<h3(?:[\s\S]*?)>([\s\S]*?)<\/h3>/g;
        let processedContent = content;

        let matches = [...content.matchAll(regexp)];
        matches.forEach(m => {
            const htmlIndex = (m[1].indexOf('<') >= 0) ? m[1].indexOf('<') : undefined;
            let m1Mod = m[1].substring(0, htmlIndex);   // strip out any superfluous html
            m1Mod = m1Mod.replace(/[^A-Za-z0-9\s]/g, '').replace(/\s/g, '_').replace(/_amp/g, '').replace(/__/g, '_');
            m1Mod = m1Mod.split('_').slice(0, 8).join('_');

            processedContent = processedContent.replace(m[0], `<h3 id="toc_${m1Mod}">${m[1]}</h3>`);
        });

        return processedContent;
    }

    render() {
        const { loaded, dataLoaded, task, notesVisible, lightboxOpen, lightboxImage, mobile } = this.state;
        const { account, financialPriorities } = this.props;

        if (!loaded || (loaded && account.loaded && !dataLoaded) || (loaded && dataLoaded && !financialPriorities)) {
            return (<Loading />);
        } else {
            const topicId = financialPriorities.tasks.filter(t => t.id === Number(task.id))[0].topic_id;
            const topic = financialPriorities.topics.filter(t => t.id === topicId)[0];
            const category = financialPriorities.categories.filter(c => c.id === topic.category_id)[0];
            const tier = financialPriorities.tiers.filter(t => t.id === category.tier_id)[0];

            const processedContent = this.processContent(task.content);

            return (
                <div className="wrapper">
                    <NavBarLoggedIn account={account} fp={financialPriorities} onFP={true} tierId={tier.id} onNavClick={null} />

                    <div className="main-bg" />

                    <div className="main task-page">
                        <div className="task-header">
                            <div className="header-nav">
                                <h1><a href={`/plan?t=${tier.id}`}>{tier.name}</a></h1>
                                <h2 className="sub">
                                    {mobile && <a href="noop" onClick={this.goBack}>{`< ${category.name}`}</a>}
                                    {!mobile && <a href={`/plan?t=${tier.id}&c=${category.id}`}>{category.name}</a>}
                                    <span> / </span>
                                    <span className="topic">{topic.name}</span>
                                </h2>
                            </div>
                            <button className={cn('primary', !task.status && 'incomplete', task.status && 'complete')} onClick={(e) => this.doMainAction(e)}>
                                <Checkbox checked={task.status === 1} onClick={(e) => this.doMainAction(e)} />
                                {(task.status) ? 'Task Completed' : 'Mark Task Complete'}
                            </button>
                        </div>

                        <div className="content-wrapper">
                            <div className="content-header">
                                <h1>
                                    {task.name}

                                    <a href="noop" className="toggle-notes" onClick={(e) => this.toggleNotes(e)}>
                                        My Notes
                                        <img src={(notesVisible) ? RemoveIcon : AddIcon} alt="Notes icon, green, plus/minus, circle" />
                                    </a>
                                </h1>
                            </div>

                            {notesVisible &&
                                <div className="task-notes">
                                    <h1>My Notes</h1>

                                    <textarea
                                        placeholder="Enter notes about next steps or items to remember from the article. Notes are saved automatically."
                                        onChange={this.saveNotes}
                                        value={task.notes}
                                    />
                                </div>
                            }

                            <div className="post-content blog-content" dangerouslySetInnerHTML={{__html: processedContent}} ref={this.contentRef} />
                        </div>
                    </div>

                    {lightboxOpen &&
                        <Lightbox mainSrc={lightboxImage} onCloseRequest={this.closeLightbox} />
                    }

                    <Footer />
                </div>
            );
        }
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(Task);
