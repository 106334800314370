import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import cn from 'classnames';
import numeral from 'numeral';
import moment from 'moment';
import ReactPlayer from 'react-player/file'
import Dialog from '@material-ui/core/Dialog';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Popper from '@material-ui/core/Popper';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { PieChart, Pie, Cell, BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip as RechartsTooltip } from 'recharts';
import DatePicker from "react-datepicker";
import Loading from 'components/Loading';
import NavBarLoggedIn from 'components/NavBarLoggedIn';
import IconButton from 'components/IconButton';
import TransactionRow from 'components/TransactionRow';
import CashflowTable from 'components/CashflowTable';
import StandardAlert from 'components/StandardAlert';
import MarkComplete from 'components/MarkComplete';
import Footer from 'components/Footer';
import { loadAccount, updateUserPreferences } from 'actions/accountActions';
import { loadFinancialPriorities, loadAssetsLiabilities } from 'actions/dataActions';
import { loadBudgetPlan, saveBudgetPlan, editBudgetPlan, deleteBudgetPlan, loadBudgetPlanMonth,
         updatePlanMonthCategory, editTransactions, deleteTransaction, editBudgetPlanMonth,
         loadBudgetPlanReview } from 'actions/budgetPlanActions';
import { downloadTransactions } from 'actions/plaidActions';
import { calculateTotal, calculateTotalPlanMonths, balancePieLabel, calculateReviewPacing } from './utils';
import { calculateAverage } from 'utils/num';
import { NUM_FORMAT, NUM_FORMAT_SHORT, DATE_FORMAT, DATAVIZ_COLORS } from 'constants/config';
import { TOOLTIP_TEXT, EMPTY_ROWS, PLAN_COLORS, VALID_ACCOUNT_TYPES } from 'constants/budgetPlanner';
import PlayCircleIcon from 'images/icons/play_circle.svg';
import OnTrackIcon from 'images/icons/on-track.svg';
import OffTrackIcon from 'images/icons/off-track.svg';
import CloseIcon from 'images/icons/close.svg';
import RefreshIcon from 'images/icons/refresh.svg';
import AddIcon from 'images/icons/add.svg';
import RemoveIcon from 'images/icons/remove.svg';
import IncomeIcon from 'images/icons/spending.svg';
import SavingsIcon from 'images/icons/savings.svg';
import SpendingIcon from 'images/icons/income.svg';
import DialogStyles from 'styles/DialogStyles.js';
import BPOverview from 'videos/bp_overview2.mp4';
import BPPlanVideo from 'videos/bp_plan.mp4';
import BPUpdateVideo from 'videos/bp_update.mp4';
import BPReviewVideo from 'videos/bp_review.mp4';
import "react-datepicker/dist/react-datepicker.css";
import './BudgetPlanner.scss';

const mapStateToProps = state => ({
    account: state.account,
    financialPriorities: state.data.financialPriorities,
    assets: state.data.assets,
    liabilities: state.data.liabilities,
    budgetPlan: state.budgetPlan.plan,
    planMonth: state.budgetPlan.planMonth,
    transactions: state.plaid.transactions || state.budgetPlan.transactions,
    manualTransactions: state.budgetPlan.manualTransactions,
    defaultCategories: state.budgetPlan.defaultCategories,
    budgetPlanReview: state.budgetPlan.budgetPlanReview,
    success: state.budgetPlan.success || state.plaid.success,
    error: state.budgetPlan.error || state.plaid.error,
});

const mapDispatchToProps = dispatch => ({
    loadAccount: () => {
        dispatch(loadAccount());
    },
    loadFinancialPriorities: (user) => {
        dispatch(loadFinancialPriorities(user));
    },
    loadAssetsLiabilities: (user) => {
        dispatch(loadAssetsLiabilities(user));
    },
    loadBudgetPlan: (user, createPlan) => {
        dispatch(loadBudgetPlan(user, createPlan));
    },
    saveBudgetPlan: (user, plan) => {
        dispatch(saveBudgetPlan(user, plan));
    },
    editBudgetPlan: (user, rowId, type) => {
        dispatch(editBudgetPlan(user, rowId, type));
    },
    deleteBudgetPlan: (user, plan) => {
        dispatch(deleteBudgetPlan(user, plan));
    },
    loadBudgetPlanMonth: (user, planId, month) => {
        dispatch(loadBudgetPlanMonth(user, planId, month));
    },
    downloadTransactions: (user, plan, accounts) => {
        dispatch(downloadTransactions(user, plan, accounts));
    },
    updatePlanMonthCategory: (user, category) => {
        dispatch(updatePlanMonthCategory(user, category));
    },
    editTransactions: (user, transactions, plan) => {
        dispatch(editTransactions(user, transactions, plan));
    },
    deleteTransaction: (user, transactionId) => {
        dispatch(deleteTransaction(user, transactionId));
    },
    editBudgetPlanMonth: (user, plan) => {
        dispatch(editBudgetPlanMonth(user, plan));
    },
    loadBudgetPlanReview: (user, planId) => {
        dispatch(loadBudgetPlanReview(user, planId));
    },
    updateUserPreferences: (preferences) => {
        dispatch(updateUserPreferences(preferences));
    },
});

class BudgetPlanner extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            dataLoaded: false,
            planId: null,
            planName: '',
            planMonth: null,
            mobile: window.innerWidth < 1440,
            edit: { success: false, error: false },
            tab: 'plan',
            startDate: null,
            endDate: null,
            allAccounts: null,
            selectedAccounts: [],
            accountsLoaded: false,
            income: [Object.assign({}, EMPTY_ROWS.income)],
            incomeTotal: 0,
            incomeReview: 'all',
            incomeReviewMonthly: 'income',
            savings: [Object.assign({}, EMPTY_ROWS.savings)],
            savingsTotal: 0,
            savingsReview: 'all',
            savingsReviewMonthly: 'savings',
            spending: [Object.assign({}, EMPTY_ROWS.spending)],
            spendingTotal: 0,
            spendingReview: 'all',
            spendingReviewMonthly: 'spending',
            updateMonth: '',
            downloadStart: null,
            downloadEnd: null,
            downloading: false,
            transactions: null,
            defaultCategories: null,
            categoryOptions: null,
            manualTransactions: [Object.assign({}, EMPTY_ROWS.manualTransactions)],
            collapsedAccounts: [],
            notesOpen: false,
            notesAnchor: null,
            updateNotes: '',
            incomeUpdated: false,
            savingsUpdated: false,
            spendingUpdated: false,
            reviewLoading: false,
            reviewTotals: null,
            reviewTotalsByMonth: null,
            barHover: {},
            pieHover: {},
            resetPlanOpen: false,
            savePlanOpen: false,
            overviewOpen: false,
            planVideoOpen: false,
            updateVideoOpen: false,
            reviewVideoOpen: false,
        };

        this.reviewRef = React.createRef()

        window.addEventListener('resize', this.handleResize);
    }

    componentDidMount() {
        this.props.loadAccount();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        let state = {};
        let account = nextProps.account;

        if (!this.state.loaded && account.user) {
            state.loaded = true;

            if (!account?.user?.bp_overview_played) {
                state.overviewOpen = true;
            }

            if (!nextProps.financialPriorities) {
                this.props.loadFinancialPriorities(account.user);
            }

            if (!this.state.accountsLoaded && !nextProps.financialPriorities) {
                this.props.loadAssetsLiabilities(account.user);
            }

            if (!this.state.dataLoaded && !nextProps.financialPriorities && !nextProps.assets && !nextProps.liabilities && !nextProps.budgetPlanReview) {
                this.props.loadBudgetPlan(account.user, true);
            }
        }

        // Load asset accounts for selection by user (if logged-in) //
        if (!this.state.accountsLoaded && (nextProps.assets || nextProps.liabilities)) {
            state.allAccounts = [
                ...nextProps.assets.filter(a => a.linked && VALID_ACCOUNT_TYPES.includes(a.type)),
                ...nextProps.liabilities.filter(a => a.linked && VALID_ACCOUNT_TYPES.includes(a.type))
            ];
            state.accountsLoaded = true;
        }

        // Load budget plan if it exists //
        if (nextProps.budgetPlan) {
            const plan = nextProps.budgetPlan;
            state.dataLoaded = true;

            if (plan.id !== null) {
                state.planId = plan.id;
                state.planName = plan.name || '';
                state.startDate = (plan.start_date) ? new Date(plan.start_date) : moment().toDate();
                state.endDate = (plan.end_date) ? new Date(plan.end_date) : moment().add(11, 'months').toDate();
                state.income = plan.records?.filter(r => r.type === 'income');
                if (!state.income || state.income.length === 0) state.income = [Object.assign({}, EMPTY_ROWS.income)];
                state.incomeTotal = calculateTotal(state.income);
                state.savings = plan.records?.filter(r => r.type === 'savings');
                if (!state.savings || state.savings.length === 0) state.savings = [Object.assign({}, EMPTY_ROWS.savings)];
                state.savingsTotal = calculateTotal(state.savings);
                state.spending = plan.records?.filter(r => r.type === 'spending');
                if (!state.spending || state.spending.length === 0) state.spending = [Object.assign({}, EMPTY_ROWS.spending)];
                state.spendingTotal = calculateTotal(state.spending.filter(s => !s.savings_withdrawal));
                state.selectedAccounts = (!plan.selected_accounts || plan.selected_accounts === '') ? [] : plan.selected_accounts.split(',');
                state.categoryOptions = [...state.income, ...state.savings, ...state.spending];
            }
        }

        // Needed to store default categories and transactions (see below) //
        const records = [...this.state.income, ...this.state.savings, ...this.state.spending];

        // Load plan month details //
        if (nextProps.planMonth) {
            const planMonth = nextProps.planMonth;

            state.planMonth = planMonth.id;
            state.updateNotes = planMonth.notes;
            state.incomeUpdated = planMonth.income_completed;
            state.savingsUpdated = planMonth.savings_completed;
            state.spendingUpdated = planMonth.spending_completed;

            if (nextProps.defaultCategories) {
                state.defaultCategories = {};
                nextProps.defaultCategories.forEach(dc => {
                    if (!state.defaultCategories[dc.account_id]) {
                        state.defaultCategories[dc.account_id] = { positive: null, negative: null };
                    }

                    if (dc.category_id) {
                        state.defaultCategories[dc.account_id][dc.positive_negative] = records.filter(r => r.id === dc.category_id)[0];
                    }
                });
            }
        }

        // Sync transactions //
        if (nextProps.transactions) {
            state.transactions = {};

            nextProps.transactions.forEach(t => {
                if (t.category) {
                    t.record = records.filter(r => r.id === t.category)[0];
                }
                if (!state.transactions[t.account_id]) {
                    state.transactions[t.account_id] = { positive: [], negative: [] };
                }
                state.transactions[t.account_id][t.positive_negative].push(t);
            });

            state.downloading = false;
        }

        // Manual transactions //
        if (nextProps.manualTransactions) {
            state.manualTransactions = nextProps.manualTransactions;
            state.manualTransactions.forEach(t => {
                t.date = (t.date && typeof(t.date) === 'string') ? moment(t.date.split('T')[0]).toDate() : null;
                if (t.category) {
                    t.record = records.filter(r => r.id === t.category)[0];
                }
            });
            if (state.manualTransactions.length === 0) state.manualTransactions = [Object.assign({}, EMPTY_ROWS.manualTransactions)];
        }

        // Review tab //
        if (nextProps.budgetPlanReview && !this.state.reviewTotals) {
            let review = nextProps.budgetPlanReview.records;
            state.reviewTotals = { all: [], income: [], incomeTotal: 0, savings: [], savingsTotal: 0, spending: [], spendingTotal: 0, savingsWithdrawalTotal: 0, total: 0 };
            state.reviewTotalsByMonth = {};

            review.forEach(r => {
                // Transaction amount logic (same as below when on Update tab) //
                let amount = Number(r.amount);
                if (r.positive_negative === 'positive' && r.type === 'spending') {
                    amount = -Math.abs(amount);
                } else if ((r.positive_negative === 'negative' || r.positive_negative === null) && r.type === 'spending') {
                    amount = Math.abs(amount);
                }

                // If we don't have a sum record for this BPR category id, create one //
                if (!state.reviewTotals.all.find(rt => rt.id === r.id)) {
                    let temp = Object.assign({}, r);
                    temp.total = 0;
                    delete temp.month;
                    state.reviewTotals.all.push(temp);
                }

                // If we don't have a monthly record for this year/month combo, set it up //
                const yearMonth = r.month.substring(0, 7);
                const formatted = moment(yearMonth).format('MMM');
                if (!state.reviewTotalsByMonth[yearMonth]) {
                    state.reviewTotalsByMonth[yearMonth] = { month: r.month, yearMonth: yearMonth, label: formatted, income: 0, savings: 0, spending: 0 };
                }
                if (!state.reviewTotalsByMonth[yearMonth][r.id]) {
                    state.reviewTotalsByMonth[yearMonth][r.id] = 0;
                }

                // Populate review totals info for plan pacing //
                const reviewTotalsAllIndex = state.reviewTotals.all.findIndex(rt => rt.id === r.id);
                state.reviewTotals.all[reviewTotalsAllIndex].total += amount;
                if (!r.savings_withdrawal) state.reviewTotals[r.type+'Total'] += amount;
                if (r.savings_withdrawal) state.reviewTotals.savingsWithdrawalTotal += amount;
                state.reviewTotals.total += amount;

                // Populate review totals by month for monthly comparison //
                state.reviewTotalsByMonth[yearMonth][r.id] += amount;
                state.reviewTotalsByMonth[yearMonth][r.type] += amount;
            });

            // Make sure to populate type arrays of review totals after all is said and done //
            ['income', 'savings', 'spending'].forEach(t => {
                state.reviewTotals[t] = state.reviewTotals.all.filter(rt => rt.type === t);
            });

            state.reviewLoading = false;
        }

        state.edit = {
            success: nextProps.success,
            error: nextProps.error,
        };

        this.setState(state);
    }

    handleResize = () => {
        this.setState({ mobile: window.innerWidth < 1440 });
    }

    closeSnackbar = () => {
        this.setState({ edit: {} });
    }

    toggleView = (e, value) => {
        if (!this.state.planId) {
            this.toggleDialog(e, 'savePlanOpen');
        } else if (value === null) {
            return false;
        } else {
            this.setState({ tab: value });
            window.scrollTo(0, 0);

            if (value === 'review') {
                this.setState({ reviewLoading: true, reviewTotals: null });
                this.props.loadBudgetPlanReview(this.props.account.user, this.state.planId);
            }
        }
    }

    toggleDialog = (e, id) => {
        e.preventDefault();
        let state = {};
        state[id] = !this.state[id];
        this.setState(state);

        if (id === 'overviewOpen') {
            const user = this.props.account.user;

            this.props.updateUserPreferences({
                user: user,
                breakdown: user.historical_breakdown,
                startDate: user.historical_start,
                endDate: user.historical_end,
                overviewPlayed: true,
            });
        }
    }

    updateState = (e, key, value) => {
        if (e) {
            e.preventDefault();
        }

        let state = {};
        state[key] = value;

        if (key === 'notesOpen' && e) {
            state.notesAnchor = (this.state.notesAnchor) ? null : e.currentTarget.parentNode;
        }

        this.setState(state);
    }

    toggleAccount = (e, account, isSection) => {
        e.preventDefault();

        // Update the correct array, depending //
        let accounts = (isSection) ? [...this.state.collapsedAccounts] : [...this.state.selectedAccounts];
        let index = accounts.indexOf(String(account.id));
        if (index === -1) {
            accounts.push(String(account.id));
        } else {
            accounts.splice(index, 1);
        }

        // Set the proper state, depending //
        let state = Object.assign({}, this.state);
        let key = (isSection) ? 'collapsedAccounts' : 'selectedAccounts';
        state[key] = accounts;
        this.setState(state);

        // Update the plan as needed //
        if (!isSection) {
            this.props.saveBudgetPlan(this.props.account.user, state);
        }

        return false;
    }

    removeRow = (type, index) => {
        let row = Object.assign({}, this.state[type][index]);

        // If they've saved the row, we need to confirm and make an API call //
        if (row.id) {
            const msg = (type === 'manualTransactions') ?
                'Are you sure you want to delete this record? All associated data will be deleted. This cannot be undone.' :
                'Are you sure you want to delete this category? Any transactions previously associated with this category will be changed to "Uncategorized." Unless recategorized, these transactions will essentially be ignored in your Income, Saving, and Spending summaries and on the Review tab.';

            if (window.confirm(msg)) {
                if (type === 'manualTransactions') {
                    this.props.deleteTransaction(this.props.account.user, row.id);
                } else {
                    this.props.editBudgetPlan(this.props.account.user, row.id, type);
                }
            } else {
                return;
            }
        }

        let state = {};
        state[type] = JSON.parse(JSON.stringify(this.state[type]));
        state[type].splice(index, 1);

        if (type !== 'manualTransactions') {
            state[type+'Total'] = calculateTotal(state[type].filter(s => !s.savings_withdrawal));
        }

        this.setState(state);
    }

    addRow = (e, type) => {
        e.stopPropagation();

        if (JSON.stringify(this.state[type][this.state[type].length - 1]) === JSON.stringify(EMPTY_ROWS[type])) {
            this.state[type].splice(this.state[type].length - 1, 1);
        }

        this.state[type].push(Object.assign({}, EMPTY_ROWS[type]));
        let state = {};
        state[type] = this.state[type];
        this.setState(state);
    }

    editRow = (e, field, type, index, mtRecord) => {
        let state = {};
        state[type] = JSON.parse(JSON.stringify(this.state[type]));

        if (field === null) {
            if (JSON.stringify(state[type][state[type].length - 1]) === JSON.stringify(EMPTY_ROWS[type])) {
                state[type].splice(state[type].length - 1, 1);
            }
            state[type].forEach(t => t.editing = false);
            state[type][index].editing = true;
            this.setState(state);
            return false;
        }

        let id = (field.value) ? field.value : field.id;
        let value = (typeof(e.value) !== 'undefined') ? e.value : e.target.value;
        if (id === 'savings_withdrawal') value = e.target.checked;
        if (type === 'manualTransactions' && id === 'category') {
            value = mtRecord.id || null;
            state[type][index].record = (mtRecord.id) ? mtRecord : null;
        }

        state[type][index][id] = value;

        if (id === 'frequency' && value !== '1.0') {
            state[type][index].low = '';
            state[type][index].high = '';
        }

        if (type !== 'manualTransactions') {
            state[type+'Total'] = calculateTotal(state[type].filter(s => !s.savings_withdrawal));
        }

        this.setState(state);
    }

    saveBudgetPlan = (key, value, type, index) => {
        let state = {};
        if (typeof(type) !== 'undefined' && typeof(index) !== 'undefined' && index >= 0) {
            state[type] = [...this.state[type]];
            state[type][index][key.id] = value?.label || value;
        } else if (key && value) {
            state[key] = value;
            if (key === 'startDate') {
                state.endDate = value;
            }
        }
        this.setState(state);
        this.props.saveBudgetPlan(this.props.account.user, Object.assign({}, this.state, state));
    }

    resetBudgetPlan = (e) => {
        if (this.state.planId) {
            this.props.deleteBudgetPlan(this.props.account.user, this.state);
        }
        this.toggleDialog(e, 'resetPlanOpen');
    }

    loadBudgetPlanMonth = (e) => {
        const val = e.target.value;
        if (val) {
            this.props.loadBudgetPlanMonth(this.props.account.user, this.state.planId, val);
        }
        this.setState({
            updateMonth: val,
            updateNotes: '',
            incomeUpdated: false,
            savingsUpdated: false,
            spendingUpdated: false,
            downloadStart: moment(val).startOf('month').toDate(),
            downloadEnd: moment(val).endOf('month').toDate(),
            transactions: null,
            manualTransactions: [Object.assign({}, EMPTY_ROWS.manualTransactions)],
        });
    }

    jumpToReview = (e) => {
        e.preventDefault();
        this.reviewRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        return false;
    }

    downloadTransactions = () => {
        if (this.state.selectedAccounts.length === 0) {
            return;
        }

        if (moment(this.state.downloadStart).isAfter(moment())) {
            alert(`Future transactions can't be downloaded, please check your input and try again.`);
            return;
        }

        this.props.downloadTransactions(
            this.props.account.user,
            this.state,
            this.state.selectedAccounts
        );

        this.setState({ downloading: true });
    }

    editDefaultCategory = (e, value, account, posNeg) => {
        let defaultCategories = Object.assign({}, this.state.defaultCategories);
        if (!defaultCategories[account.id]) {
            defaultCategories[account.id] = { positive: null, negative: null };
        }
        defaultCategories[account.id][posNeg] = (value?.id) ? value : null;

        let transactions = Object.assign({}, this.state.transactions);
        if (transactions[account.id] && transactions[account.id][posNeg]) {
            if (value?.id) {
                transactions[account.id][posNeg].forEach(t => { t.category = value.id; t.record = value; });
            } else {
                transactions[account.id][posNeg].forEach(t => { t.category = null; t.record = null; });
            }
        }

        this.setState({
            defaultCategories: defaultCategories,
            transactions: transactions,
        });

        this.props.updatePlanMonthCategory(this.props.account.user, {
            planMonthId: this.state.planMonth,
            accountId: account.id,
            categoryId: (value?.id) ? value.id : null,
            positiveNegative: posNeg,
        });

        this.props.editTransactions(
            this.props.account.user,
            transactions[account.id][posNeg],
            this.state
        );
    }

    editTransaction = (e, value, transaction, account, posNeg, field) => {
        if (transaction.type === 'synced') {
            let transactions = JSON.parse(JSON.stringify(this.state.transactions));
            transactions[account.id][posNeg].forEach(t => {
                if (t.id === transaction.id) {
                    if (field === 'category') {
                        t.category = (value?.id) ? value.id : null;
                        t.record = (value?.id) ? value : null;
                        t.new = false;
                    } else if (field === 'ignore') {
                        t.ignore = !t.ignore;
                        if (e.target.checked) {
                            t.category = null;
                        }
                        t.new = false;
                    }
                    transaction = t;
                }
            });

            this.setState({ transactions: transactions });
        } else {
            transaction[field] = value;
        }

        this.props.editTransactions(
            this.props.account.user,
            [transaction],
            this.state
        );
    }

    editBudgetPlanMonth = (e, key, value, num) => {
        e.stopPropagation();

        if (['incomeUpdated', 'savingsUpdated', 'spendingUpdated'].includes(key) && num && num > 0 && !this.state[key]) {
            const verb = (num === 1) ? 'is' : 'are';
            const suffix = (num === 1) ? '' : 's';

            alert(`There ${verb} ${num} transaction${suffix} remaining to be categorized, please categorize or ignore all transactions before marking complete. Remember, you can always use the "Default Category" option to categorize transactions in bulk.`);
            return;
        }

        let state = {};
        state.planMonth = this.state.planMonth;
        state[key] = (value) ? value : !this.state[key];
        this.setState(state);
        this.props.editBudgetPlanMonth(this.props.account.user, state);
    }

    changeReviewSource = (e, type, monthly) => {
        let state = {};

        if (monthly) {
            state[type+'ReviewMonthly'] = e.target.value;
        } else {
            state[type+'Review'] = e.target.value;
        }

        this.setState(state);
    }

    chartMouseOut = () => {
        this.setState({ barHover: {} });
    }

    chartMouseMove = (opts) => {
        const payload = (opts.activePayload) ? opts.activePayload[0].payload : {};
        const barHover = { ...payload, index: opts.activeTooltipIndex };

        if (barHover.index !== this.state.barHover.index) {
            this.setState({ barHover: barHover });
        }
    }

    pieChartMouseOver = (opts) => {
        let { fill, percent, type, category_label } = opts, total = opts.payload.total;

        this.setState({
            pieHover: { type, fill, category_label, percent, total },
        });
    }

    pieChartMouseOut = () => {
        this.setState({ pieHover: {} });
    }

    render() {
        const { loaded, dataLoaded, mobile, edit, tab, downloading, allAccounts, selectedAccounts } = this.state;   // Shared state across all tabs
        const { planName, startDate, endDate, accountsLoaded, income, incomeTotal, savings, savingsTotal, spending, spendingTotal } = this.state;    // Plan tab
        const { updateMonth, downloadStart, downloadEnd, transactions, manualTransactions, defaultCategories, categoryOptions, collapsedAccounts } = this.state;     // Update tab
        const { notesOpen, notesAnchor, updateNotes, incomeUpdated, savingsUpdated, spendingUpdated } = this.state;     // Update tab, Summary & Review
        const { reviewLoading, reviewTotals, reviewTotalsByMonth, incomeReviewMonthly, savingsReviewMonthly, spendingReviewMonthly, barHover, pieHover } = this.state;     // Review tab
        const { resetPlanOpen, savePlanOpen, overviewOpen, planVideoOpen, updateVideoOpen, reviewVideoOpen } = this.state;     // Dialog open/closed states
        const { account, financialPriorities, classes } = this.props;

        if (!loaded || (loaded && account.loaded && !dataLoaded)) {
            return (<Loading />);
        } else {
            // Total plan months used to calculate a few things //
            const start = moment(startDate).startOf('month').startOf('day');
            const end = moment(endDate).endOf('month');
            const totalPlanMonths = (start && end) ? calculateTotalPlanMonths(start, end) : 1;

            // Max and min valid start/end dates //
            const startMin = moment().subtract(11, 'months').startOf('month').toDate();
            const startMax = moment().add(11, 'months').startOf('month').toDate();
            const endMin = start.toDate();
            const endMax = moment(start).add(11, 'months').toDate();

            // Pie chart data stuff //
            const hasData = (incomeTotal > 0 || savingsTotal > 0 || spendingTotal > 0);
            const pieData = (hasData) ?
                [{ name: 'Income', value: incomeTotal }, { name: 'Savings', value: savingsTotal }, { name: 'Spending', value: spendingTotal }] :
                [{ name: 'Income', value: 1000 }, { name: 'Savings', value: 500 }, { name: 'Spending', value: 500 }];
            const endAngle = 90 - (((pieData[1].value + pieData[2].value) / pieData[0].value) * 90);
            const balance = (hasData) ? incomeTotal - (savingsTotal + spendingTotal) : undefined;
            const pieChartMargin = (mobile) ? { top: 0, right: 0, bottom: 0, left: 0 } : { top: 80, right: 100, bottom: 0, left: 150 };

            // Update month vars //
            let updateMonths = [], current = moment(startDate);
            for (let i = 0; i < totalPlanMonths; i++) {
                updateMonths.push({ value: current.startOf('month').toISOString(), label: current.format('MMMM, YYYY'), yearMonth: current.format('YYYY-MM') });
                current.add(1, 'months');
            }
            const updateMonthText = (updateMonth) ? moment(updateMonth).format('MMMM') : '';
            const updateMonthBold = <label>for <b>{updateMonthText}</b></label>;
            const updateIsCurrentMonth = (updateMonth && moment(updateMonth).isSame(moment(), 'month'));

            // Download & sync start/end limits //
            const downloadStartMin = moment(downloadStart).subtract(1, 'months').startOf('month').toDate();
            const downloadStartMax = moment(downloadEnd).add(1, 'months').endOf('month').toDate();
            const downloadEndMin = downloadStart;
            const downloadEndMax = moment(downloadEnd).add(1, 'months').endOf('month').toDate();

            // Notification bar shens //
            let updatesRemaining = 0;
            if (!incomeUpdated) updatesRemaining++;
            if (!savingsUpdated) updatesRemaining++;
            if (!spendingUpdated) updatesRemaining++;
            const showNotificationBar = moment(updateMonth).isBefore(moment(), 'month') && updatesRemaining > 0;

            // Notice if they select dates outside of the current month //
            const showNotice = moment(downloadStart).isBefore(moment(updateMonth).startOf('month')) ||
                moment(downloadEnd).isAfter(moment(updateMonth).endOf('month'));

            // Custom transaction headers by account type //
            const accountHeaders = {
                'savings': { positive: 'Deposits & Credits', negative: 'Purchases & Transfers' },
                'checking': { positive: 'Deposits & Credits', negative: 'Purchases & Transfers' },
                'credit card': { positive: 'Payments & Credits', negative: 'Purchases' },
            };

            // Category map for easy reference //
            let categoryMap = {};
            categoryOptions.filter(r => r.id).forEach(r => {
                r.displayType = (r?.type) ? r.type.substring(0,1).toUpperCase() + r.type.substring(1) : 'n/a';
                r.monthlyTotal = 0;
                categoryMap[r.id] = r;
            });

            // Summary & Review section of Update tab (define it here so we can update below) //
            let summaryReviewData = {
                'income': { id: 'income', name: 'Income', icon: IncomeIcon, alt: 'Income icon, monochrome, dollar bills in envelope', goal: incomeTotal, monthlyTotal: 0 },
                'savings': { id: 'savings', name: 'Savings', icon: SavingsIcon, alt: 'Savings icon, monochrome, piggy bank with coin peeking out the top', goal: savingsTotal, monthlyTotal: 0 },
                'spending': { id: 'spending', name: 'Spending', icon: SpendingIcon, alt: 'Spending icon, monochrome, hand holding dollar bills', goal: spendingTotal, monthlyTotal: 0 },
            };

            // Per-account category summaries //
            let accountSummaries = {}, numUncategorized = 0;
            if (transactions) {
                Object.keys(transactions).forEach(acctId => {
                    accountSummaries[acctId] = { income: 0, savings: 0, spending: 0, savings_withdrawals: 0 };
                    transactions[acctId].positive.forEach(t => {
                        if (!t.ignore && t.category) {
                            if (t.record.type !== 'spending') {
                                if (!t.record.savings_withdrawal) {
                                    accountSummaries[acctId][t.record.type] += Number(t.amount);
                                } else {
                                    accountSummaries[acctId].savings_withdrawals += Number(t.amount);
                                }
                                if (!t.record.savings_withdrawal) summaryReviewData[t.record.type].monthlyTotal += Number(t.amount);
                                categoryMap[t.record.id].monthlyTotal += Number(t.amount);
                            } else {
                                if (!t.record.savings_withdrawal) {
                                    accountSummaries[acctId][t.record.type] -= Math.abs(Number(t.amount));
                                } else {
                                    accountSummaries[acctId].savings_withdrawals -= Math.abs(Number(t.amount));
                                }
                                if (!t.record.savings_withdrawal) summaryReviewData[t.record.type].monthlyTotal -= Math.abs(Number(t.amount));
                                categoryMap[t.record.id].monthlyTotal -= Math.abs(Number(t.amount));
                            }
                        }

                        if (!t.category && !t.ignore) {
                            numUncategorized += 1;
                        }
                    });
                    transactions[acctId].negative.forEach(t => {
                        if (!t.ignore && t.category) {
                            if (t.record.type !== 'spending') {
                                if (!t.record.savings_withdrawal) {
                                    accountSummaries[acctId][t.record.type] += Number(t.amount);
                                } else {
                                    accountSummaries[acctId].savings_withdrawals += Number(t.amount);
                                }
                                if (!t.record.savings_withdrawal) summaryReviewData[t.record.type].monthlyTotal += Number(t.amount);
                                categoryMap[t.record.id].monthlyTotal += Number(t.amount);
                            } else {
                                if (!t.record.savings_withdrawal) {
                                    accountSummaries[acctId][t.record.type] += Math.abs(Number(t.amount));
                                } else {
                                    accountSummaries[acctId].savings_withdrawals += Math.abs(Number(t.amount));
                                }
                                if (!t.record.savings_withdrawal) summaryReviewData[t.record.type].monthlyTotal += Math.abs(Number(t.amount));
                                categoryMap[t.record.id].monthlyTotal += Math.abs(Number(t.amount));
                            }
                        }

                        if (!t.category && !t.ignore) {
                            numUncategorized += 1;
                        }
                    });
                });
            }

            // Manual transaction summaries //
            let manualSummaries = { income: 0, savings: 0, spending: 0, savings_withdrawals: 0 };
            if (manualTransactions) {
                manualTransactions.forEach(t => {
                    if (t.record) {
                        if (!t.record.savings_withdrawal) {
                            manualSummaries[t.record.type] += Number(t.amount);
                        } else {
                            manualSummaries.savings_withdrawals += Number(t.amount);
                        }

                        // Add it to Summary & Review tab as well //
                        if (!t.record.savings_withdrawal) summaryReviewData[t.record.type].monthlyTotal += Number(t.amount);
                        categoryMap[t.record.id].monthlyTotal += Number(t.amount);
                    }
                });
            }

            // Review tab vars //
            let reviewPacing, pace;
            if (tab === 'review' && reviewTotals) {
                ({ reviewPacing, pace } = calculateReviewPacing(start, end, totalPlanMonths, reviewTotals, this.state));
            }
            const planStarted = moment().isSameOrAfter(start);

            // Pad monthly comparison data if necessary //
            let monthlyComparisonData = [];
            if (tab === 'review' && reviewTotalsByMonth) {
                updateMonths.forEach(m => {
                    const formatted = moment(m.yearMonth).format('MMM');

                    if (!reviewTotalsByMonth[m.yearMonth]) {
                        monthlyComparisonData.push({ month: m.value, yearMonth: m.yearMonth, label: formatted, income: 0, savings: 0, spending: 0 });
                    } else {
                        monthlyComparisonData.push(reviewTotalsByMonth[m.yearMonth]);
                    }
                });
            }

            // Figure out legend contents //
            let legendPayload = [], currentMonth = moment().startOf('month');
            let currentMonthInPlan = monthlyComparisonData.filter(d => moment(d.yearMonth).isSame(currentMonth));
            let dataToShow = (Object.keys(barHover).length > 0) ? barHover : null || currentMonthInPlan[0] || monthlyComparisonData[monthlyComparisonData.length - 1];
            if (tab === 'review' && dataToShow) {
                if (updateMonths[0] && moment(updateMonths[0].value).isAfter(currentMonth)) {
                    legendPayload.push({ type: 'text', label: null, value: 'Data will show once your plan has started and data has been saved.' });
                } else {
                    legendPayload.push({ type: 'text', label: null, value: moment(dataToShow.yearMonth).format('MMMM YYYY') });
                    [incomeReviewMonthly, savingsReviewMonthly, spendingReviewMonthly].forEach((key, i) => {
                        legendPayload.push({
                            type: (categoryMap[key]) ? categoryMap[key].type : key,
                            label: (categoryMap[key]) ? categoryMap[key].source || categoryMap[key].category : key.substring(0,1).toUpperCase() + key.substring(1),
                            value: numeral(dataToShow[key]).format(NUM_FORMAT_SHORT),
                            color: PLAN_COLORS[i]
                        });
                    });
                }
            }

            // The contents of the snackbar on API calls //
            let alert = null;
            if (edit.success) {
                alert = { severity: 'success', msg: edit.success.msg };
            } else if (edit && edit.error && edit.error.body) {
                alert = { severity: 'error', msg: edit.error.body.msg };
            }

            return (
                <>
                    {downloading &&
                        <div className="downloading">
                            <div className="status">
                                <img src={RefreshIcon} alt="Refresh icon, green, circular arrow" />
                                <h1>Downloading Transactions</h1>
                            </div>
                        </div>
                    }

                    <div className="wrapper">
                        <NavBarLoggedIn account={account} fp={financialPriorities} />

                        <div className="main-bg" />

                        <div className="main budget-planner">
                            <div className="sticky-header">
                                <ToggleButtonGroup value={tab} exclusive onChange={this.toggleView}>
                                    <ToggleButton value="plan">Plan</ToggleButton>
                                    <ToggleButton value="update">Update</ToggleButton>
                                    <ToggleButton value="review">Review</ToggleButton>
                                </ToggleButtonGroup>

                                <h1>Budget Planner</h1>

                                <div className="help" onClick={(e) => this.toggleDialog(e, `${tab}VideoOpen`)}>
                                    <span>{tab.substring(0, 1).toUpperCase() + tab.substring(1) + ' Tab Help'}</span>
                                    <img src={PlayCircleIcon} alt="Play video icon, right-pointing triangle in a circle, green" />
                                </div>

                                {tab === 'plan' && <h2><a href="noop" onClick={(e) => this.toggleDialog(e, 'overviewOpen')}>Watch the one minute overview</a>. Then, create a <b>plan</b> for income, saving, & spending. Focus on your most important accounts & categories.</h2>}
                                {tab === 'update' && <h2><b>Update</b> your monthly data by syncing transactions for the month and assigning them to the appropriate income, saving or spending category.</h2>}
                                {tab === 'review' && <h2><b>Review</b> your progress to check <b>pacing</b> against your timeline, <b>compare</b> data by month, and see <b>breakdowns</b> by accounts and categories.</h2>}
                            </div>

                            {tab === 'plan' && <>
                                <section>
                                    <div className="subsection">
                                        <h3>Plan Name</h3>
                                        <h4>What do you want to call this plan?</h4>
                                        <input name="plan-name"
                                            onChange={(e) => this.updateState(e, 'planName', e.target.value)}
                                            onBlur={this.saveBudgetPlan}
                                            value={planName}
                                            placeholder="Enter Plan Name" />
                                    </div>

                                    <div className="subsection alt">
                                        <h3>Plan Timeframe & Accounts to Sync</h3>

                                        <h4>What month do you want this budget plan to start and end? <IconButton type="info" content="?" tooltip={TOOLTIP_TEXT.startEnd} /></h4>
                                        <div className="start-end-date">
                                            <div className="date-wrapper">
                                                <label>Start:</label>
                                                <DatePicker className="datepicker" showMonthYearPicker
                                                    dateFormat="MMMM yyyy" selected={startDate}
                                                    minDate={startMin} maxDate={startMax}
                                                    onChange={(date) => this.saveBudgetPlan('startDate', date)} />
                                            </div>

                                            <div className="date-wrapper">
                                                <label>End:</label>
                                                <DatePicker className="datepicker" showMonthYearPicker
                                                    dateFormat="MMMM yyyy" selected={endDate}
                                                    minDate={endMin} maxDate={endMax}
                                                    onChange={(date) => this.saveBudgetPlan('endDate', date)} />
                                            </div>

                                            <span>Your plan is {totalPlanMonths} months.</span>&nbsp;
                                            <a href="noop" className="edit-plan-timeline" onClick={(e) => this.toggleDialog(e, 'resetPlanOpen')}>Edit Plan Timeline</a>
                                        </div>

                                        <h4>Which linked accounts will you categorize transactions from? <IconButton type="info" content="?" tooltip={TOOLTIP_TEXT.linkedAccounts} /></h4>
                                        {!accountsLoaded && <ul className="accounts"><Loading /></ul>}
                                        {accountsLoaded && allAccounts.length === 0 && <h4>You haven't added any linked accounts. <a href="/assetsLiabilities">Add some now</a>. <b>Note:</b> Without linking your bank accounts, you won't be able to sync transactions.</h4>}
                                        {accountsLoaded && allAccounts.length > 0 &&
                                            <ul className="accounts">
                                                {allAccounts.map(a => {
                                                    return (
                                                        <li key={a.id}>
                                                            <Checkbox id={a.name} checked={selectedAccounts.includes(String(a.id))} onClick={(e) => this.toggleAccount(e, a)} />
                                                            <label htmlFor={a.name}>{a.name}</label>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        }
                                    </div>

                                    <div className="subsection">
                                        <h3>Expected Net Income <IconButton type="info" content="?" tooltip={TOOLTIP_TEXT.expectedNetIncome} /></h3>

                                        <h4><b>Income Sources:</b> Enter your expected after-tax monthly income. For irregular income, enter the average monthly range.</h4>
                                        <div className="table-and-summary">
                                            <CashflowTable
                                                type="income"
                                                classes="even"
                                                tab="plan"
                                                fields={[
                                                    { id: 'source', label: 'Source', placeholder: 'Income Name' },
                                                    { id: 'frequency', label: 'Frequency', options: [
                                                        { value: '4', name: 'Weekly' },
                                                        { value: '1', name: 'Once a Month' },
                                                        { value: '2', name: 'Twice a month' },
                                                        { value: '1.0', name: 'Irregular' },
                                                    ] },
                                                    { id: 'amount', label: 'Amount', placeholder: '$0' },
                                                    { id: 'notes', label: '' },
                                                    { id: 'average', label: 'Average Monthly' },
                                                ]}
                                                rows={income}
                                                removeRow={this.removeRow}
                                                addRow={this.addRow}
                                                editRow={this.editRow}
                                                onBlur={this.saveBudgetPlan}
                                                mobile={mobile}
                                            />
                                            <div className="summary">
                                                <p>
                                                    Your plan for monthly net income is about:
                                                    <span className="summary-amount income">{numeral(incomeTotal).format(NUM_FORMAT)}</span>
                                                </p>
                                                <p>For duration of plan: <b>{numeral(incomeTotal * totalPlanMonths).format(NUM_FORMAT)}</b></p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="subsection alt">
                                        <h3>Expected Net Saving <IconButton type="info" content="?" tooltip={TOOLTIP_TEXT.expectedNetSavings} /></h3>

                                        <h4><b>Savings Descriptions:</b> Enter the after-tax income you expect to allocate to savings each month. You can add a description or an account name.</h4>
                                        <div className="table-and-summary">
                                            <CashflowTable
                                                type="savings"
                                                tab="plan"
                                                fields={[
                                                    { id: 'source', label: 'Savings Description', placeholder: 'Enter description...', tooltip: TOOLTIP_TEXT.savingsDescription },
                                                    { id: 'amount', label: 'Average Monthly', placeholder: '$0', tooltip: TOOLTIP_TEXT.averageMonthlySavings },
                                                    { id: 'notes', label: '' },
                                                ]}
                                                rows={savings}
                                                removeRow={this.removeRow}
                                                addRow={this.addRow}
                                                editRow={this.editRow}
                                                onBlur={this.saveBudgetPlan}
                                                mobile={mobile}
                                            />
                                            <div className="summary">
                                                <p>
                                                    Your plan for monthly after-tax savings is about:
                                                    <span className="summary-amount savings">{numeral(savingsTotal).format(NUM_FORMAT)}</span>
                                                </p>
                                                <p>For duration of plan: <b>{numeral(savingsTotal * totalPlanMonths).format(NUM_FORMAT)}</b></p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="subsection">
                                        <h3>Expected Spending <IconButton type="info" content="?" tooltip={TOOLTIP_TEXT.expectedSpending} /></h3>

                                        <h4>
                                            <b>Spending Items:</b> Enter your expected spending. Track spending by total account spending, category spending, or a mix of the two.&nbsp;
                                            <a href="https://www.moneyswell.com/action-plan/track-your-cashflow-with-cashflow-goals/" target="_blank" rel="noreferrer">Learn more</a>.
                                        </h4>

                                        <div className="table-and-summary">
                                            <CashflowTable
                                                type="spending"
                                                tab="plan"
                                                fields={[
                                                    { id: 'category', label: 'Account/Category', tooltip: TOOLTIP_TEXT.accountCategory },
                                                    { id: 'amount', label: 'Average Monthly', placeholder: '$0',
                                                        sub: '(Total Cost if Savings Withdrawal)', tooltip: TOOLTIP_TEXT.averageMonthlySpending },
                                                    { id: 'notes', label: '' },
                                                    { id: 'savings_withdrawal', label: 'Savings Withdrawal?', tooltip: TOOLTIP_TEXT.savingsWithdrawal },
                                                ]}
                                                rows={spending}
                                                removeRow={this.removeRow}
                                                addRow={this.addRow}
                                                editRow={this.editRow}
                                                onBlur={this.saveBudgetPlan}
                                                mobile={mobile}
                                            />
                                            <div className="summary">
                                                <p>
                                                    Your plan for monthly spending is about:
                                                    <span className="summary-amount spending">{numeral(spendingTotal).format(NUM_FORMAT)}</span>
                                                </p>
                                                <p>For duration of plan: <b>{numeral(spendingTotal * totalPlanMonths).format(NUM_FORMAT)}</b></p>
                                            </div>
                                        </div>
                                    </div>
                                </section>

                                <button className="primary larger" onClick={(e) => this.toggleView(e, 'update')}>Go to Update</button>

                                <section className="balance-check">
                                    <div className="subsection">
                                        <h3>Budget Balance Check <IconButton type="info" content="?" tooltip={TOOLTIP_TEXT.budgetBalanceCheck} /></h3>

                                        <h4>This shows how your monthly plan for income compares to average saving and spending. Try to ensure
                                        your total of planned savings and spending do not exceed your total income.</h4>

                                        <div className="summary">
                                            {(typeof(balance) != 'undefined' && balance >= 0) && <>
                                                <div className="summary-row">
                                                    <img src={OnTrackIcon} alt="On track badge, green, checkmark" />
                                                    <span>Well done! With this plan on average you'll have an extra <b>{numeral(balance).format(NUM_FORMAT)}</b>. This means your budget is balanced.</span>
                                                </div>
                                                <div className="summary-row">
                                                    <img src={OnTrackIcon} alt="On track badge, green, checkmark" />
                                                    <span>You should be able to meet your planned <b className="income">income</b>, <b className="savings">saving</b>, and <b className="spending">spending</b> goals.</span>
                                                </div>
                                            </>}
                                            {(typeof(balance) != 'undefined' && balance < 0) && <>
                                                <div className="summary-row">
                                                    <img src={OffTrackIcon} alt="Off track badge, red, down arrow" />
                                                    <span>Your current plan is <b>imbalanced by {numeral(-balance).format(NUM_FORMAT)}</b> a month.</span>
                                                </div>
                                                <div className="summary-row">
                                                    <img src={OffTrackIcon} alt="Off track badge, red, down arrow" />
                                                    <span>To balance your plan, you need to earn more <b className="income">income</b>, plan to <b className="savings">save</b> less, or reduce <b className="spending">spending</b>.</span>
                                                </div>
                                            </>}
                                        </div>

                                        <div className="pie-chart">
                                            <PieChart width={(mobile) ? 200 : 750} height={(mobile) ? 200 : ((balance < 0) ? 500 : 300)} margin={pieChartMargin}>
                                                <Pie
                                                    dataKey="value"
                                                    startAngle={180}
                                                    endAngle={endAngle}
                                                    data={pieData}
                                                    cx={(mobile) ? '50%' : 250}
                                                    cy={(mobile) ? '50%' : 235}
                                                    outerRadius={(mobile) ? '80%' : 200}
                                                    label={(mobile) ? null : (options) => balancePieLabel(options, this.state)}
                                                    labelLine={hasData && !mobile}
                                                    legendType="circle"
                                                >
                                                    {pieData.map((entry, i) => <Cell key={i} fill={(hasData) ? PLAN_COLORS[i] : '#E6E9ED'} />)}
                                                </Pie>
                                            </PieChart>

                                            <div className="legend">
                                                <div className="legend-row">
                                                    <label className="income">Income</label>
                                                    <span className="summary-amount income">{numeral(incomeTotal).format(NUM_FORMAT)}</span>
                                                </div>
                                                <div className="legend-row">
                                                    <label className="savings">Savings</label>
                                                    <span className="summary-amount savings">{numeral(savingsTotal).format(NUM_FORMAT)}</span>
                                                </div>
                                                <div className="legend-row">
                                                    <label className="spending">Spending</label>
                                                    <span className="summary-amount spending">{numeral(spendingTotal).format(NUM_FORMAT)}</span>
                                                </div>
                                            </div>

                                            {hasData &&
                                                <Tooltip title="Try not to have your spending extend below the balance line." arrow={true} placement="top-end" className={classes.Tooltip}>
                                                    <div className="balance-line" style={{ top: (mobile) ? '92px' : ((balance < 0) ? '308px' : '293px')}}>
                                                        <div className="line" />
                                                        <label>Balance Line</label>
                                                    </div>
                                                </Tooltip>
                                            }
                                         </div>
                                    </div>
                                </section>
                            </>}

                            {tab === 'update' && <>
                                <section>
                                    <div className="subsection alt update-month">
                                        <h3>Update Month <IconButton type="info" content="?" tooltip={TOOLTIP_TEXT.updateMonth} /></h3>

                                        <h4>What month are you updating numbers for?</h4>
                                        <div className="start-end-date">
                                            <select className="update-month" onChange={this.loadBudgetPlanMonth} value={updateMonth}>
                                                <option value=''>-- Select --</option>
                                                {updateMonths.map((m, i) => <option value={m.value} key={i}>{m.label}</option>)}
                                            </select>
                                        </div>

                                        {showNotificationBar &&
                                            <div className="notification-bar">
                                                {updatesRemaining > 0 && <span className="badge">{updatesRemaining}</span>}
                                                <p>When you've finished your updates, <a href="noop" onClick={this.jumpToReview}>jump to Review</a> to confirm completion. If needed, you can make edits later.</p>
                                            </div>
                                        }
                                    </div>

                                    {updateMonth &&
                                        <div className="subsection">
                                            <h3>Sync & Categorize Transactions <IconButton type="info" content="?" tooltip={TOOLTIP_TEXT.syncCategorizeTransactions} /></h3>

                                            <h4>Based on your plan, MoneySwell will sync transactions for the selected month, from the accounts you selected on the Plan tab.</h4>
                                            <div className="download-transactions">
                                                <span className="text">Sync from</span>
                                                <DatePicker className="datepicker"
                                                    selected={downloadStart} minDate={downloadStartMin} maxDate={downloadStartMax}
                                                    onChange={(date) => this.updateState(null, 'downloadStart', date)} />
                                                <span className="text">through</span>
                                                <DatePicker className="datepicker"
                                                    selected={downloadEnd} minDate={downloadEndMin} maxDate={downloadEndMax}
                                                    onChange={(date) => this.updateState(null, 'downloadEnd', date)} />
                                                <span className="text">Previously downloaded transactions will not be duplicated.</span>
                                                <button className={cn('primary', selectedAccounts.length === 0 && 'disabled')} onClick={this.downloadTransactions}>Sync Now</button>
                                                {selectedAccounts.length === 0 && <IconButton type="info" content="?" tooltip={TOOLTIP_TEXT.syncDisabled} />}
                                            </div>

                                            {showNotice &&
                                                <div className="out-of-month-notice">
                                                    <span className="badge">!</span>
                                                    <p><b>Notice:</b> You have selected to sync transactions outside of the month you are updating
                                                    your numbers for. <a href="https://moneyswell.com" target="_blank" rel="noreferrer">Learn more</a>.</p>
                                                </div>
                                            }

                                            {allAccounts.filter(a => selectedAccounts.includes(String(a.id))).map(a => {
                                                const lastSync = (a.last_sync) ? moment(a.last_sync).format(DATE_FORMAT) : '---';
                                                const transactionsExist = (transactions && transactions[a.id] && (transactions[a.id].positive || transactions[a.id].negative));
                                                const defaultExists = (defaultCategories && defaultCategories[a.id]);
                                                const defaultPositive = (defaultExists && defaultCategories[a.id].positive);
                                                const defaultNegative = (defaultExists && defaultCategories[a.id].negative);
                                                const summary = accountSummaries[a.id];

                                                return (
                                                    <div key={a.id} className={cn('account-transactions', collapsedAccounts.includes(String(a.id)) && 'collapsed')}>
                                                        <div className="header">
                                                            <h5>
                                                                {a.type === 'checking' && <span className="dot income" />}
                                                                {['savings', 'checking'].includes(a.type) && <span className="dot savings" />}
                                                                {['credit card', 'checking'].includes(a.type) && <span className="dot spending" />}
                                                                {a.name}
                                                                <IconButton type="info" content="?" tooltip={TOOLTIP_TEXT.accountTips[a.type]} />
                                                            </h5>
                                                            <span className="last-sync"><b>Last sync:</b> {lastSync}</span>
                                                            {collapsedAccounts.includes(String(a.id)) ?
                                                                <ChevronRightIcon onClick={(e) => this.toggleAccount(e, a, true)} /> :
                                                                <ExpandMoreIcon onClick={(e) => this.toggleAccount(e, a, true)} />
                                                            }
                                                        </div>

                                                        <div className="transaction-table positive">
                                                            <div className="header">
                                                                <div className="column title"><b>{accountHeaders[a.type].positive}</b></div>
                                                                <div className="column category">
                                                                    <b>Default Category:</b>
                                                                    {defaultPositive && defaultCategories[a.id].positive.type && <span className={cn('dot', defaultCategories[a.id].positive.type)} />}
                                                                    <Autocomplete
                                                                        className="default-category"
                                                                        options={categoryOptions}
                                                                        groupBy={(option) => option.displayType}
                                                                        getOptionLabel={(option) => option.source || option.category || ''}
                                                                        onChange={(e, value) => this.editDefaultCategory(e, value, a, 'positive')}
                                                                        value={(defaultPositive) ? defaultCategories[a.id].positive : ''}
                                                                        renderOption={(option) => {
                                                                            let text = option.source || option.category;
                                                                            if (option.savings_withdrawal) {
                                                                                text += ' - Savings Withdrawal';
                                                                            }
                                                                            return (
                                                                                <span className={cn('option', option.type)} data-option={option}>{text}</span>
                                                                            );
                                                                        }}
                                                                        renderInput={(params) => {
                                                                            if (defaultPositive &&  defaultCategories[a.id].positive.savings_withdrawal) {
                                                                                params.inputProps.value += ' - Savings Withdrawal';
                                                                            }

                                                                            return (
                                                                                <div ref={params.InputProps.ref}>
                                                                                    <input type="text" {...params.inputProps} title={params.inputProps.value} placeholder="-- Uncategorized --" />
                                                                                </div>
                                                                            );
                                                                        }} />
                                                                </div>
                                                                <div className="column ignore">
                                                                    Ignore
                                                                    <IconButton type="info" content="?" tooltip={TOOLTIP_TEXT.ignore[a.type].positive} />
                                                                </div>
                                                            </div>
                                                            <div className="body">
                                                                {transactionsExist && transactions[a.id].positive.map(t => {
                                                                    return (
                                                                        <TransactionRow key={t.id} t={t} a={a} pn="positive" options={categoryOptions} editTransaction={this.editTransaction} />
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>

                                                        <div className="transaction-table negative">
                                                            <div className="header">
                                                                <div className="column title"><b>{accountHeaders[a.type].negative}</b></div>
                                                                <div className="column category">
                                                                    <b>Default Category:</b>
                                                                    {defaultNegative && defaultCategories[a.id].negative.type && <span className={cn('dot', defaultCategories[a.id].negative.type)} />}
                                                                    <Autocomplete
                                                                        className="default-category"
                                                                        options={categoryOptions}
                                                                        groupBy={(option) => option.displayType}
                                                                        getOptionLabel={(option) => option.source || option.category || ''}
                                                                        onChange={(e, value) => this.editDefaultCategory(e, value, a, 'negative')}
                                                                        value={(defaultNegative) ? defaultCategories[a.id].negative : ''}
                                                                        renderOption={(option) => {
                                                                            let text = option.source || option.category;
                                                                            if (option.savings_withdrawal) {
                                                                                text += ' - Savings Withdrawal';
                                                                            }
                                                                            return (
                                                                                <span className={cn('option', option.type)}>{text}</span>
                                                                            );
                                                                        }}
                                                                        renderInput={(params) => {
                                                                            if (defaultNegative &&  defaultCategories[a.id].negative.savings_withdrawal) {
                                                                                params.inputProps.value += ' - Savings Withdrawal';
                                                                            }

                                                                            return (
                                                                                <div ref={params.InputProps.ref}>
                                                                                    <input type="text" {...params.inputProps} title={params.inputProps.value} placeholder="-- Uncategorized --" />
                                                                                </div>
                                                                            );
                                                                        }} />
                                                                </div>
                                                                <div className="column ignore">
                                                                    Ignore
                                                                    <IconButton type="info" content="?" tooltip={TOOLTIP_TEXT.ignore[a.type].negative} />
                                                                </div>
                                                            </div>
                                                            <div className="body">
                                                                {transactionsExist && transactions[a.id].negative.map(t => {
                                                                    return (
                                                                        <TransactionRow key={t.id} t={t} a={a} pn="negative" options={categoryOptions} editTransaction={this.editTransaction} />
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>

                                                        {summary &&
                                                            <div className="transaction-summary">
                                                                <span>{a.name} Summary:</span>
                                                                {Object.keys(summary).map(type => {
                                                                    const dotType = (type === 'savings_withdrawals') ? 'spending' : type;
                                                                    return (
                                                                        <div className="summary-item" key={type}>
                                                                            <span className={cn('dot', dotType)} />
                                                                            <label>{type.substring(0,1).toUpperCase() + type.substring(1).replace('_', ' ')}:</label>
                                                                            <span className="amount">&nbsp;{numeral(summary[type]).format(NUM_FORMAT)}</span>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        }
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    }

                                    {updateMonth &&
                                        <div className="subsection">
                                            <h3>Manual Transactions <IconButton type="info" content="?" tooltip={TOOLTIP_TEXT.manualTransactions} /></h3>
                                            <h4>Add any manual transactions {updateMonthText && updateMonthBold} to your income, saving, or spending categories.</h4>

                                            <CashflowTable
                                                type="manualTransactions"
                                                fields={[
                                                    { id: 'date', label: 'Date', min: downloadStart, max: downloadEnd },
                                                    { id: 'name', label: 'Name' },
                                                    { id: 'amount', label: 'Amount', placeholder: '$0' },
                                                    { id: 'category', label: 'Label', options: categoryOptions },
                                                ]}
                                                rows={manualTransactions}
                                                removeRow={this.removeRow}
                                                addRow={this.addRow}
                                                editRow={this.editRow}
                                                onBlur={this.editTransaction}
                                                mobile={mobile}
                                            />

                                            <div className="transaction-summary">
                                                <span>Manual Transactions Summary:</span>
                                                {Object.keys(manualSummaries).map(type => {
                                                    const dotType = (type === 'savings_withdrawals') ? 'spending' : type;
                                                    return (
                                                        <div className="summary-item" key={type}>
                                                            <span className={cn('dot', dotType)} />
                                                            <label>{type.substring(0,1).toUpperCase() + type.substring(1).replace('_', ' ')}:</label>
                                                            <span className="amount">&nbsp;{numeral(manualSummaries[type]).format(NUM_FORMAT)}</span>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    }
                                </section>

                                {updateMonth &&
                                    <>
                                        <section className="summary-review" ref={this.reviewRef}>
                                            {updateMonth && updatesRemaining > 0 && showNotificationBar && <span className="badge">{updatesRemaining}</span>}

                                            <div className="subsection">
                                                <h3>Summary & Review {updateMonthText && `for ${updateMonthText}`} <IconButton type="info" content="?" tooltip={TOOLTIP_TEXT.summaryReview} /></h3>
                                                <h4>Based on the above transactions, below is your summary of Income, Saving, and Spending {updateMonthText && updateMonthBold}.</h4>

                                                {updateMonth &&
                                                    <a href="noop" className="toggle-notes" onClick={(e) => this.updateState(e, 'notesOpen', !notesOpen)}>
                                                        My Notes
                                                        <img src={(notesOpen) ? RemoveIcon : AddIcon} alt="Notes icon, green, plus/minus, circle" />
                                                    </a>
                                                }
                                                {notesOpen &&
                                                    <Popper id="summaryNotes" open={notesOpen} anchorEl={notesAnchor} disablePortal={true}>
                                                        <div className="note">
                                                            <textarea placeholder="Add your note here." onChange={(e) => this.updateState(e, 'updateNotes', e.target.value)}
                                                                onBlur={(e) => this.editBudgetPlanMonth(e, 'updateNotes', e.target.value)} value={updateNotes || ''} />
                                                        </div>
                                                    </Popper>
                                                }
                                            </div>

                                            {updateMonth && Object.keys(summaryReviewData).map(type => {
                                                let data = summaryReviewData[type];
                                                data.pace = (data.goal === 0) ? 0 : Math.max(0, (data.monthlyTotal / data.goal) * 100);
                                                data.onTrack = (type === 'spending') ? data.monthlyTotal <= data.goal : data.monthlyTotal >= data.goal;

                                                const typeKey = (type === 'spending') ? 'spending' : 'incomeOrSavings';
                                                const paceKey = (data.pace < 100) ? 'below' : ((data.pace === 100) ? 'at' : 'over');
                                                const currentPastKey = (updateIsCurrentMonth) ? 'current': 'past';
                                                const incomeSavingsVerb = (type === 'income') ? 'earn' : 'save';
                                                const goalWord = (type === 'spending') ? 'max' : 'goal';

                                                const subSummaryText = {
                                                    'incomeOrSavings': {
                                                        'below': {
                                                            'current': `You need to ${incomeSavingsVerb} ${numeral(data.goal - data.monthlyTotal).format(NUM_FORMAT_SHORT)} more to hit your ${updateMonthText} ${type} goal`,
                                                            'past': `You missed your ${type} goal by ${numeral(data.goal - data.monthlyTotal).format(NUM_FORMAT_SHORT)}.`
                                                        },
                                                        'at': {
                                                            'current': `Congratulations! You met your ${type} goal!`,
                                                            'past': `Congratulations! You met your ${type} goal!`,
                                                        },
                                                        'over': {
                                                            'current': `Congratulations! You beat your ${type} goal by ${numeral(data.monthlyTotal - data.goal).format(NUM_FORMAT_SHORT)}.`,
                                                            'past': `Congratulations! You beat your ${type} goal by ${numeral(data.monthlyTotal - data.goal).format(NUM_FORMAT_SHORT)}.`,
                                                        },
                                                    },
                                                    'spending': {
                                                        'below': {
                                                            'current': `Congratulations! You're under your max spending by ${numeral(data.goal - data.monthlyTotal).format(NUM_FORMAT_SHORT)}.`,
                                                            'past': `Congratulations! You beat your max spending by ${numeral(data.goal - data.monthlyTotal).format(NUM_FORMAT_SHORT)}.`,
                                                        },
                                                        'at': {
                                                            'current': `You hit your max spending for the month.`,
                                                            'past': `You hit your max spending for the month.`,
                                                        },
                                                        'over': {
                                                            'current': `So far, you've overspent by ${numeral(data.monthlyTotal - data.goal).format(NUM_FORMAT_SHORT)}.`,
                                                            'past': `You overshot your spending goal by ${numeral(data.monthlyTotal - data.goal).format(NUM_FORMAT_SHORT)}.`,
                                                        },
                                                    },
                                                };

                                                const summaryTotalText = {
                                                    'incomeOrSavings': {
                                                        'current': `In ${updateMonthText}, your total net ${type} so far is: `,
                                                        'past': `In ${updateMonthText}, your total net ${type} was: `,
                                                    },
                                                    'spending': {
                                                        'current': `In ${updateMonthText}, your total spending so far is: `,
                                                        'past': `In ${updateMonthText}, your total spending was: `,
                                                    },
                                                };

                                                return (
                                                    <div className="subsection review" key={data.id}>
                                                        <div className="content-wrapper">
                                                            <div className="category-summary">
                                                                <img src={data.icon} alt={data.alt} />
                                                                <label>{data.name + ' Total'}</label>
                                                                <div className="pacing-bar">
                                                                    <div className={cn('pace', (data.onTrack) ? 'on-track' : 'off-track', data.pace >= 99 && 'full', data.pace === 0 && 'zero')} style={{width: data.pace+'%'}}>
                                                                        <label className={cn('total', (data.onTrack) ? 'on-track' : 'off-track', data.pace >= 15 && 'inside', data.pace === 0 && 'zero')}>
                                                                            {numeral(data.monthlyTotal).format(NUM_FORMAT)}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="category-sub-summary">
                                                                {data.onTrack && <img src={OnTrackIcon} alt="On track badge, green, checkmark" />}
                                                                {!data.onTrack && <img src={OffTrackIcon} alt="Off track badge, red, down arrow" />}

                                                                <div className="sub-summary-text">{subSummaryText[typeKey][paceKey][currentPastKey]}</div>
                                                                <div className={cn('sub-summary-percent', data.onTrack && 'on-track')}>{data.pace.toFixed(1) + '%'}</div>
                                                                <div className="sub-summary-goal">{numeral(data.goal).format(NUM_FORMAT)} <span>{goalWord}</span></div>
                                                            </div>

                                                            <div className="category-summary-table">
                                                                <div className="header">
                                                                    <div className="column icon">&nbsp;</div>
                                                                    <div className="column source">Source</div>
                                                                    <div className="column actual">Actual</div>
                                                                    <div className="column goal">Goal</div>
                                                                    <div className="column achievement">Achievement</div>
                                                                </div>
                                                                <div className="body">
                                                                    {this.state[type].map(r => {
                                                                        if (!categoryMap[r.id]) {
                                                                            return null;
                                                                        }

                                                                        console.log(r);

                                                                        let amount = (r.amount !== 'NaN') ? r.amount : calculateAverage(r.low, r.high);
                                                                        if (r.frequency) amount *= Number(r.frequency);
                                                                        let onTrack = (r.type === 'spending') ? categoryMap[r.id].monthlyTotal <= amount : categoryMap[r.id].monthlyTotal >= amount;
                                                                        let icon = (onTrack) ? OnTrackIcon : OffTrackIcon;
                                                                        let alt = (onTrack) ? 'On track badge, green, checkmark' : 'Off track badge, red, down arrow';
                                                                        let pace = Math.max(0, (categoryMap[r.id].monthlyTotal / amount) * 100);

                                                                        return (
                                                                            <div className="row" key={r.id}>
                                                                                <div className="field icon"><img src={icon} alt={alt} /></div>
                                                                                <div className="field source">{r.source || r.category}</div>
                                                                                <div className="field actual">{numeral(categoryMap[r.id].monthlyTotal).format(NUM_FORMAT)}</div>
                                                                                <div className="field goal">{numeral(amount).format(NUM_FORMAT)}</div>
                                                                                <div className="field achievement">
                                                                                    <div className="pacing-bar">
                                                                                        <div className={cn('pace', (onTrack) ? 'on-track' : 'off-track', pace >= 99 && 'full', pace === 0 && 'zero')} style={{width: pace+'%'}} />
                                                                                    </div>
                                                                                    <span>{pace.toFixed(0) + '%'}</span>
                                                                                </div>
                                                                                {r.type === 'spending' && r.savings_withdrawal && <div className="field withdrawal">{'* Savings Withdrawal'}</div>}
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </div>

                                                            <div className="summary-total">
                                                                <p>
                                                                    {summaryTotalText[typeKey][currentPastKey]}
                                                                    <span className={cn('summary-amount', type)}>{numeral(data.monthlyTotal).format(NUM_FORMAT)}</span>
                                                                </p>
                                                                {type === 'spending' && <span className="subnote">{'* Summarized total does not include any Savings Withdrawals.'}</span>}
                                                            </div>
                                                        </div>

                                                        <div className={cn('mark-complete-bar', type)}>
                                                            <div className="content-wrapper">
                                                                {!this.state[type+'Updated'] && <span className="badge">!</span>}
                                                                {!this.state[type+'Updated'] && <p>Have you completed all your <b>{type}</b> updates for {updateMonthText}?</p>}
                                                                {this.state[type+'Updated'] && <p>You've indicated all your <b>{type}</b> updates are complete for {updateMonthText}.</p>}
                                                                <MarkComplete completed={this.state[type+'Updated']} onClick={(e) => this.editBudgetPlanMonth(e, `${type}Updated`, e.target.checked, numUncategorized)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </section>

                                        <button className="primary larger" onClick={(e) => this.toggleView(e, 'review')}>Go to Review</button>
                                    </>
                                }
                            </>}

                            {tab === 'review' && <>
                                {reviewLoading && <Loading />}

                                {!reviewLoading && <>
                                    <section className="plan-pacing">
                                        <div className="background">
                                            <div className="header">
                                                <div className="desc">
                                                    <h3>Plan Pacing <IconButton type="info" content="?" tooltip={TOOLTIP_TEXT.planPacing} /></h3>
                                                    <h4>Today: <span>{moment().format(DATE_FORMAT)}</span> <span className="pacing-legend" /></h4>
                                                </div>
                                            </div>

                                            <div className="months">
                                                {updateMonths.map((m, i, arr) => {
                                                    let classes = cn('month', planStarted && moment(m.value).isSame(moment(), 'month') && 'active');
                                                    return (
                                                        <div className={classes} key={i} style={{width: `${(100 / arr.length)}%`}}>
                                                            {m.label.substr(0,3)}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>

                                        <div className="foreground">
                                            {reviewPacing.map((rp, i) => {
                                                let label = (rp.id === 'spending') ? 'Spending' : `Net ${rp.name}`;
                                                if (mobile && rp.review !== 'all') {
                                                    const record = rp.records.filter(r => String(r.id) === rp.review)[0];
                                                    label = record?.source || record?.category;
                                                }

                                                return (
                                                    <div className="pacing-section" key={i}>
                                                        <div className={cn('pacing-metadata', i % 2 !== 0 && 'even', rp.id)}>
                                                            <div className="category-info">
                                                                <img src={rp.icon} alt={rp.alt} />
                                                                <label>{label}</label>
                                                                <select name="review" onChange={(e) => this.changeReviewSource(e, rp.id)} value={rp.review}>
                                                                    <option value="all">All {rp.name}</option>
                                                                    {rp.records.filter(r => r.id).map(r => <option value={r.id} key={r.id}>{r?.source || r?.category}</option>)}
                                                                </select>
                                                            </div>
                                                            <div className="summary">
                                                                {(rp.onTrack || !planStarted) && <>
                                                                    <img src={OnTrackIcon} alt="On track badge, green, checkmark" />
                                                                    <span>Congrats! You're on track. You could {rp.verb} {rp.connector} <b>{numeral(rp.nowNeedAmount).format(NUM_FORMAT_SHORT)}</b> a month {rp.suffix}.</span>
                                                                </>}
                                                                {!rp.onTrack && planStarted && <>
                                                                    <img src={OffTrackIcon} alt="Off track badge, red, down arrow" />
                                                                    <span>To meet your goal, you now need to {rp.verb} an average of <b>{numeral(rp.nowNeedAmount).format(NUM_FORMAT_SHORT)}</b> per month.</span>
                                                                </>}
                                                            </div>
                                                        </div>
                                                        <div className="pacing-bar review">
                                                            <div className="bar">
                                                                <div className={cn('pace', (rp.onTrack) ? 'on-track' : 'off-track', rp.pace >= 99 && 'full', rp.pace === 0 && 'zero')}
                                                                    style={{width: Math.min(100, rp.pace)+'%'}}>
                                                                    <label className={cn('total', (rp.onTrack) ? 'on-track' : 'off-track', rp.pace >= 15 && 'inside', rp.pace === 0 && 'zero')}>
                                                                        {numeral(rp.total).format(NUM_FORMAT)}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="achievement-goal">
                                                            <label className={cn('achievement', (rp.onTrack) ? 'on-track' : 'off-track')}>
                                                                {(rp.barGoal === 0) ? '0%' : ((rp.total / rp.barGoal) * 100).toFixed(1) + '%'} <span>{(rp.id === 'spending') ? 'of max' : 'achievement'}</span>
                                                            </label>
                                                            <label className="goal">{numeral(rp.barGoal).format(NUM_FORMAT)} <span>{(rp.id === 'spending') ? 'max' : 'goal'}</span></label>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>

                                        {planStarted &&
                                            <div className="today-container">
                                                {pace * 100 >= 0 && pace * 100 <= 100 &&
                                                    <div className="marker" style={{left: Math.floor(pace*100) + '%'}} />
                                                }
                                            </div>
                                        }
                                    </section>

                                    <section className="monthly-comparison">
                                        <div className="comparison-header">
                                            <h3>Monthly Comparison <IconButton type="info" content="?" tooltip={TOOLTIP_TEXT.monthlyComparison} /></h3>

                                            <div className="category-selects">
                                                {reviewPacing.map((rp, i) => {
                                                    return (
                                                        <div key={i}>
                                                            <span className={cn('dot square', rp.id)} />
                                                            <select name="review" onChange={(e) => this.changeReviewSource(e, rp.id, 'monthly')} value={this.state[rp.id+'ReviewMonthly']} key={i}>
                                                                <option value={rp.id}>All {rp.name}</option>
                                                                {rp.records.filter(r => r.id).map(r => <option value={r.id} key={r.id}>{r?.source || r?.category}</option>)}
                                                            </select>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>

                                        <div className="chart">
                                            <BarChart width={1030} height={275} data={monthlyComparisonData} margin={{ top: 10, right: 30, bottom: 10, left: 15, }} onMouseMove={this.chartMouseMove} onMouseLeave={this.chartMouseOut}>
                                                <RechartsTooltip contentStyle={{display: 'none'}} cursor={{ fill: '#E6ECEE' }} />
                                                <CartesianGrid stroke="#E6E6E6" vertical={false} />
                                                <XAxis dataKey="label" minTickGap={0} axisLine={false} tickLine={false} tick={{stroke: '#B3B3B3', fill: '#B3B3B3', strokeWidth: 0.01}} />
                                                <YAxis tickFormatter={t => numeral(t).format(NUM_FORMAT_SHORT)} tickCount={8} axisLine={false} tickLine={false} tick={{stroke: '#B3B3B3', fill: '#B3B3B3', strokeWidth: 0.01}} />
                                                <Bar dataKey={incomeReviewMonthly} fill={PLAN_COLORS[0]} />
                                                <Bar dataKey={savingsReviewMonthly} fill={PLAN_COLORS[1]} />
                                                <Bar dataKey={spendingReviewMonthly} fill={PLAN_COLORS[2]} />
                                            </BarChart>
                                        </div>

                                        <div className="legend">
                                            <ul>
                                                {legendPayload.map((lp, i) => {
                                                    return (
                                                        <li key={i}>
                                                            {lp.type !== 'text' && <div className={`dot square ${lp.type}`} />}
                                                            {lp.label && <label style={{ color: lp.color }}>{lp.label}</label>} &nbsp;
                                                            <span>{lp.value}</span>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    </section>

                                    <section className="cashflow-breakdown">
                                        <div className="subsection">
                                            <h3>Cashflow Breakdown <IconButton type="info" content="?" tooltip={TOOLTIP_TEXT.cashflowBreakdown} /></h3>

                                            <div className="breakdowns">
                                                {reviewPacing.map((rp, i) => {
                                                    const categoryName = (rp.id === 'spending') ? rp.name : 'Net ' + rp.name;

                                                    let legendData = null;
                                                    if ((Object.keys(pieHover).length === 0 || pieHover.type !== rp.id) && reviewTotals[rp.id]) {
                                                        const top = reviewTotals[rp.id].sort((a, b) => {
                                                            return (a.total > b.total) ? -1 : ((a.total < b.total) ? 1 : 0);
                                                        })[0];
                                                        const total = reviewTotals[rp.id].reduce((acc, obj) => { return acc + obj.total; }, 0)

                                                        legendData = {
                                                            type: rp.id,
                                                            fill: DATAVIZ_COLORS[0],
                                                            category_label: top?.category_label,
                                                            percent: (top) ? top.total / total : 0,
                                                            total: top?.total || 0,
                                                        };
                                                    } else if (Object.keys(pieHover).length > 0 && pieHover.type === rp.id) {
                                                        legendData = pieHover;
                                                    }

                                                    return (
                                                        <div className="breakdown-section" key={i}>
                                                            <h2>
                                                                <img src={rp.colorIcon} alt={rp.alt} />
                                                                {categoryName}
                                                            </h2>
                                                            <p>Your total {categoryName} is {numeral(rp.total + rp.swTotal).format(NUM_FORMAT)}.</p>

                                                            <div className="pie-chart">
                                                                <PieChart width={150} height={150}>
                                                                    <Pie
                                                                        dataKey="total"
                                                                        data={reviewTotals[rp.id]}
                                                                        outerRadius={75}
                                                                        label={null}
                                                                        labelLine={false}
                                                                        onMouseOver={this.pieChartMouseOver}
                                                                        onMouseOut={this.pieChartMouseOut}
                                                                    >
                                                                        {reviewTotals[rp.id].map((e, i) => <Cell key={i} fill={DATAVIZ_COLORS[i % DATAVIZ_COLORS.length]} />)}
                                                                    </Pie>
                                                                </PieChart>
                                                            </div>

                                                            <div className="breakdown-legend">
                                                                {legendData?.type === rp.id && legendData.percent > 0 && legendData.total > 0 &&
                                                                    <div className="legend-contents">
                                                                        <span className="icon" style={{ backgroundColor: legendData.fill }} />
                                                                        <label style={{ color: legendData.fill }}>{legendData.category_label}: </label>
                                                                        <span className="amounts">
                                                                            {`${Math.round(legendData.percent * 100)}%, ${numeral(legendData.total).format(NUM_FORMAT)}`}
                                                                        </span>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </section>
                                </>}
                            </>}
                        </div>

                        <Dialog onClose={(e) => this.toggleDialog(e, 'resetPlanOpen')} open={resetPlanOpen} className={classes.Dialog}>
                            <img src={CloseIcon} className="close-dialog" alt="Close icon, X, grey" onClick={(e) => this.toggleDialog(e, 'resetPlanOpen')} />
                            <h1 className="solo">Warning</h1>
                            <p>Editing your plan's timeframe will clear out any data previously entered and effectively reset your plan.</p>
                            <p>Are you sure you want to reset your plan timeframe and clear data?</p>
                            <div className="actions">
                                <button className="secondary" onClick={(e) => this.toggleDialog(e, 'resetPlanOpen')}>Cancel</button>
                                <button className="primary" onClick={this.resetBudgetPlan}>Yes, Reset Plan</button>
                            </div>
                        </Dialog>

                        <Dialog onClose={(e) => this.toggleDialog(e, 'savePlanOpen')} open={savePlanOpen} className={classes.Dialog}>
                            <img src={CloseIcon} className="close-dialog" alt="Close icon, X, grey" onClick={(e) => this.toggleDialog(e, 'savePlanOpen')} />
                            <p>You need to save your plan before you can update or review it.</p>
                            <p>Click "Save Plan" at the bottom of the page.</p>
                            <button className="primary" onClick={(e) => this.toggleDialog(e, 'savePlanOpen')}>Ok</button>
                        </Dialog>

                        <Dialog onClose={(e) => this.toggleDialog(e, 'overviewOpen')} open={overviewOpen} className={cn(classes.Dialog, 'video-lightbox')}>
                            <img src={CloseIcon} className="close-dialog" alt="Close icon, X, grey" onClick={(e) => this.toggleDialog(e, 'overviewOpen')} />
                            <ReactPlayer url={BPOverview} playing={overviewOpen} controls={true} width={1100} height={600} />
                        </Dialog>

                        <Dialog onClose={(e) => this.toggleDialog(e, 'planVideoOpen')} open={planVideoOpen} className={cn(classes.Dialog, 'video-lightbox')}>
                            <img src={CloseIcon} className="close-dialog" alt="Close icon, X, grey" onClick={(e) => this.toggleDialog(e, 'planVideoOpen')} />
                            <ReactPlayer url={BPPlanVideo} playing={planVideoOpen} controls={true} width={1100} height={600} />
                        </Dialog>

                        <Dialog onClose={(e) => this.toggleDialog(e, 'updateVideoOpen')} open={updateVideoOpen} className={cn(classes.Dialog, 'video-lightbox')}>
                            <img src={CloseIcon} className="close-dialog" alt="Close icon, X, grey" onClick={(e) => this.toggleDialog(e, 'updateVideoOpen')} />
                            <ReactPlayer url={BPUpdateVideo} playing={updateVideoOpen} controls={true} width={1100} height={600} />
                        </Dialog>

                        <Dialog onClose={(e) => this.toggleDialog(e, 'reviewVideoOpen')} open={reviewVideoOpen} className={cn(classes.Dialog, 'video-lightbox')}>
                            <img src={CloseIcon} className="close-dialog" alt="Close icon, X, grey" onClick={(e) => this.toggleDialog(e, 'reviewVideoOpen')} />
                            <ReactPlayer url={BPReviewVideo} playing={reviewVideoOpen} controls={true} width={1100} height={600} />
                        </Dialog>

                        <Dialog open={mobile} className={classes.Dialog}>
                            <h1>Mobile version coming soon!</h1>
                        </Dialog>

                        {!!alert && <StandardAlert onClose={this.closeSnackbar} alert={alert} />}

                        <Footer />
                    </div>
                </>
            );
        }
    }
}

export default compose(
    withStyles(Object.assign({}, DialogStyles)),
    connect(mapStateToProps, mapDispatchToProps)
)(BudgetPlanner);
