
const styles = {
    Dialog: {
        '& .MuiDialog-paper': {
            width: '705px',
            position: 'relative',
            boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.15);',
            backgroundColor: '#F4F6F8',
            borderRadius: '10px',
            padding: '30px',
            textAlign: 'center',
            color: '#26273F',

            '& .close-dialog': {
                position: 'absolute',
                right: '15px',
                top: '15px',
                width: '20px',
                height: '20px',
                cursor: 'pointer',
            },

            '& p': {
                margin: '40px 10px 30px 10px',
            },

            '& button': {
                margin: '0 auto',
            },

            '& .actions': {
                display: 'flex',
                width: '55%',
                margin: '0 auto',
                justifyContent: 'space-around',
                alignItems: 'flex-end',
            },
        },

        '& .MuiDialog-paperWidthSm': {
            maxWidth: '705px',
        },

        '@media (max-width: 1439px)': {
            '& .MuiDialog-paper': {
                width: '75%',
                backgroundColor: '#FFFFFF',

                '& .close-dialog': {
                    width: '25px !important',
                    height: '25px !important',
                },

                '& h2': {
                    fontWeight: '400',
                    fontSize: '14px',
                    lineHeight: '17px',
                    padding: '20px 0 7px 0',
                    margin: '30px 0 7px 0',
                },

                '& h1': {
                    margin: '0 0 40px 0',

                    '&.solo': {
                        margin: '50px 0 30px 0',
                    }
                },

                '& p': {
                    margin: '0 0 20px 0 !important',
                },

                '& .actions': {
                    width: 'auto !important',
                    margin: '0 !important',

                    '& button': {
                        padding: '5px 10px !important',
                    },
                },

                '& .account-types': {
                    flexDirection: 'column',
                    margin: '0',

                    '& .type': {
                        width: 'calc(100% - 30px)',
                        backgroundColor: '#F4F6F8',
                        padding: '20px 15px',
                        margin: '0 0 20px 0',

                        '& p': {
                            margin: '15px 15px 0 15px',
                            fontSize: '14px',
                            lineHeight: '17px',
                        },

                        '& ul': {
                            margin: '0',
                            fontSize: '14px',
                            lineHeight: '17px',
                        },

                        '& button.primary': {
                            margin: '0 auto 20px auto',
                        },
                    },
                },

                '& .account-dialog-content': {
                    '& .adc-wrapper': {
                        margin: '0',
                        padding: '0',
                        flexDirection: 'column',

                        '& .back-link': {
                            position: 'absolute',
                            top: '18px',
                            left: '20px',
                            width: '50px',
                        },

                        '& #metadata, & #reminderFrequency': {
                            width: '105%',
                            margin: '0',
                            backgroundColor: '#F4F6F8',
                            borderRadius: '10px',

                            '& h2': {
                                fontWeight: '700',
                            },

                            '& input': {
                                width: '220px',
                                margin: '0',
                                fontSize: '16px',
                                lineHeight: '19px',

                                '&::placeholder': {
                                    fontSize: '16px',
                                    lineHeight: '19px',
                                },
                            },

                            '& select': {
                                width: '242px',
                                margin: '0',
                                fontSize: '16px',
                                lineHeight: '19px',
                            },
                        },

                        '& button.primary': {
                            margin: '25px auto !important',
                        },
                    },

                    '& .delete-account': {
                        margin: '0',
                        padding: '10px 0 0 0',

                        '& h2': {
                            margin: '0 0 5px 0',
                            padding: '0',
                            fontWeight: '700',
                        },

                        '& input': {
                            margin: '20px 20px 0 0',
                        },

                        '& button.delete': {
                            margin: '20px 0 0 0',
                        },
                    },
                },
            },
        },
    },
};

export default styles;
